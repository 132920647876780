import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import Landing from "../components/landing";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { getCategories } from "../lib/helpers";
import BlockContent from "../components/block-content";
import { responsiveTitle1 } from "../components/typography.module.css";

export const query = graphql`
  query AboutPageQuery {
    about: sanityAbout(_id: { regex: "/(drafts.|)about/" }) {
      seo {
        title
        metaKeyWords
        metaDescription
      }
      _rawBody
      slug {
        current
      }
      title
      mainImage {
        asset {
          _id
        }
        alt
      }
    }
  }
`;

const CategoriesPage = (props) => {
  const { data, errors } = props;
  const { about } = data;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  return (
    <Layout>
      <SEO title="Rólunk" />
      <Container>
        <h1 hidden className={responsiveTitle1}>{about.title}</h1>
        {about && about.mainImage && <Landing mainImage={about.mainImage}/>}
        {about && about._rawBody && <BlockContent blocks={about._rawBody || []} />}
      </Container>
    </Layout>
  );
};

export default CategoriesPage;
