import React from "react";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import PropTypes from 'prop-types';
import * as styles from "./landing.module.css";

function Project(props) {
  const { mainImage } = props;
  return (
    <div className={styles.mainImage}>
          <img
            src={imageUrlFor(buildImageObj(mainImage))
              .width(1200)
              .height(Math.floor((9 / 16) * 1000))
              .fit("crop")
              .url()}
            alt={mainImage.alt}
          />
        </div>
  );
}

export default Project;
Project.propTypes = {
  mainImage: PropTypes.object,
};