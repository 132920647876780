// extracted by mini-css-extract-plugin
export var categories = "landing-module--categories--b69E+";
export var categoriesHeadline = "landing-module--categoriesHeadline--9FsvI typography-module--base--FphVn";
export var grid = "landing-module--grid--kIDy4";
export var mainContent = "landing-module--mainContent--eGXPJ";
export var mainImage = "landing-module--mainImage--K7Wcr";
export var metaContent = "landing-module--metaContent--pmaDe";
export var relatedProjects = "landing-module--relatedProjects--fG7OH";
export var relatedProjectsHeadline = "landing-module--relatedProjectsHeadline--ZuLOw typography-module--base--FphVn";
export var root = "landing-module--root--Greuj";
export var title = "landing-module--title--jsF7U typography-module--responsiveTitle1--0t0jA";